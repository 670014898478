<template>
	<div class="convention-hall page">
		<van-nav-bar class="nav-bar" :title="$t('choose.1')" />
		<div class="convention-item">
			<van-tabs animated sticky line-width="100px" :swipeable="true">
				<van-tab :title="$t('choose.2')">
					<div class="card">{{ $t('choose.3') }}</div>
					<div class="address">
						<van-row type="flex" justify="" gutter="65" v-for="(val, key) in addlist" :key="key">
							<van-col span="" offset="" @click="addgo(v)" v-for="(v, k) in val" :key="k">{{
								handleTitle(v)
							}}</van-col>
						</van-row>
					</div>
				</van-tab>
				<van-tab :title="$t('choose.4')">
					<div class="card">
						{{ $t('choose.3') }}</div>
					<div class="rig-box">
						<p class="rig-title">
							{{ $t('choose.5') }}?</p>
						<p class="rig-content">
							{{ $t('choose.6') }}</p>
						<p class="rig-title">
							{{ $t('choose.7') }}</p>
						<p class="rig-content">
							{{ $t('choose.8') }}</p>
						<p class="rig-content">
							{{ $t('choose.3') }}</p>
					</div>
				</van-tab>
			</van-tabs>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			addlist: [
				{
					0: this.$t('choose.9'),
					1: this.$t('choose.10'),
					2: this.$t('choose.11'),
					3: this.$t('choose.12')
				},
				{
					0: this.$t('choose.13'),
					1: this.$t('choose.14'),
					2: this.$t('choose.15'),
					3: this.$t('choose.16')
				}
			]
		};
	},
	methods: {
		handleTitle(item) {
			switch (this.$i18n.locale) {
				case 'zh':
					return item.name;
				case 'en':
					return item.name_en;
				case 'ko':
					return item.name_ka;
				case 'ja':
					return item.name_jp;
				default:
					return item.name;
			}
		},
		addgo(data) {
			if (!localStorage.getItem('token')) {
				this.$router.push({ path: '/Login' });
			} else {
				this.$router.push({ path: '/list?id=' + data.id + '&name=' + this.handleTitle(data) });
			}
		},
		getAddress() {
			this.$http({
				method: 'get',
				url: 'address_list'
			}).then(res => {
				this.addlist = res.data;
			});
		}
	},
	created() {
		this.getAddress();
	}
};
</script>

<style lang="less" scoped>
.page {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	background-color: #f2f2f5;
}

.nav-bar {
	background: linear-gradient(90deg, #7e5678, #e6c3a1);
	height: 100px;
}

.van-nav-bar {
	line-height: 50px;
}

::v-deep .van-nav-bar__title {
	max-width: 60%;
	margin: 0 auto;
	color: #ffffff;
	font-size: 35px;
}

::v-deep .van-nav-bar__content {
	height: 100px;
}

.van-sidebar {
	width: 180px;
}

.convention-hall {
	display: flex;
	flex-direction: column;
	bottom: 20px;
	background: #f2f2f5;
}

::v-deep .van-tab {
	font-size: 30px;
	line-height: 100px;
	font-weight: bold;
}

::v-deep .van-tabs__line {
	background-color: #7e5678;
}

::v-deep .van-tabs--line .van-tabs__wrap {
	height: 100px;
}

::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}

.card {
	background-color: #8a637d;
	padding: 0.625rem;
	width: 95%;
	color: white;
	font-size: 0.8125rem;
	margin: 0.625rem auto;
	border-radius: 0.375rem;
}

::v-deep .van-row--flex {
	height: 80px;
	line-height: 80px;
}

.rig-box {
	width: 95%;
	margin: 0.625rem auto;
}

.rig-title {
	color: #0bdab0;
	font-size: 1.125rem;
}

.rig-content {
	font-size: 20px;
	// margin-top: 10px;
}

.address {
	width: 90%;
	margin: 0 auto;
}
</style>
