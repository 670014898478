export default {
  login: "ログイン",
  register: "登録",
  deposit: "入金",
  withdraw: "出金",
  confirm: "確認",
  refreshSuccess: "更新成功",
  save: "保存",
  submit: "提出",
  loginPage: {
    1: "ユーザー名を入力してください",
    2: "パスワードを入力してください",
    3: "パスワードを忘れましたか？",
    4: "アカウントがありませんか？今すぐ登録",
  },
  registerPage: {
    1: "ユーザー名を入力してください（6〜12文字の英字または数字）",
    2: "ログインパスワードを入力してください（6〜12文字の英字または数字）",
    3: "招待コードを入力してください",
    4: `「アカウント開設契約」の各条項に同意しました`,
    5: "ユーザー名を入力してください！",
    6: "パスワードを入力してください！",
    7: "下のアカウント開設契約にチェックを入れてください！",
  },
  minePage: {
    1: "私の財布",
    2: "詳細",
    3: "残高",
    4: "個人レポート",
    5: "アカウント明細",
    6: "タスク記録",
    7: "個人センター",
    8: "情報公告",
    9: "オンラインカスタマーサービス",
    10: "",
    11: "タスクを完了してから入ってください",
    12: "カスタマーサービスに連絡して入金してください",
    13: "受取カードを設定してください！",
    14: "機能が無効になっています！",
    15: "アカウントオフライン",
    16: "ログイン/登録",
    17: "ログインすると、より多くのサービスを利用できます！",
  },
  BindCard: {
    1: "受取カードを記入",
    2: "受取カード情報を入力してください",
    3: "銀行カード番号",
    4: "正しい銀行カード番号を入力してください",
    5: "銀行名",
    6: "銀行を選択してください",
    7: "尊敬するユーザー様、資金の安全を確保するために、実名を登録し、出金パスワードを設定してください。名前と口座名義が一致しない場合、出金できません。",
    8: "カードを確認してバインド",
  },
  GameRecord: {
    1: "タスク記録",
    2: "データが空です！",
    3: "かわいい",
    4: "純粋な",
    5: "フェティッシュ",
    6: "セクシー",
    7: "ベット金額：",
    8: "注文時間：",
    9: "決済時間：",
    10: "",
  },
  Infomation: {
    1: "基本情報",
    2: "アバター",
    3: "アバターを選択",
    4: "本名",
    5: "未設定",
    6: "性別",
    7: "男",
    8: "女",
    9: "不明",
    10: "バインド情報",
    11: "バインド済み",
    12: "なし",
  },
  Notice: {
    1: "システム公告",
  },
  Personalreport: {
    1: "個人レポート",
    2: "利益金額（元）",
    3: "利益計算式 : 当選金額 - タスク金額",
    4: "タスク金額",
    5: "入金金額",
    6: "出金金額",
    7: "当選金額",
  },
  ServiceOnline: {
    1: "オンラインカスタマーサービス",
    2: "ミツアナグマエンターテインメント",
    3: "連絡先",
    4: "年中無休で24時間体制でサービスを提供しています",
  },
  Setbank: {
    1: "受取カード情報",
    2: "受取カードを追加",
    3: "ヒント: 大手商業銀行をバインドしてください。変更が必要な場合は、オンラインカスタマーサービスに連絡してください",
    4: "名前を設定してから銀行カードをバインドしてください！",
    5: "出金パスワードを設定してから銀行カードをバインドしてください！",
  },
  SetLoginPassword: {
    1: "ログインパスワードの変更",
    2: "旧パスワード",
    3: "新しいパスワード",
    4: "旧パスワードを入力してください",
    5: "新しいパスワードを入力してください",
    6: "新しいパスワードを再入力してください",
    7: "すべて記入してください！",
    8: "2回のパスワード入力が一致しません",
  },
  Setname: {
    1: "本名の変更",
    2: "名前",
    3: "本名を入力してください",
    4: "アカウントの安全のために、本名はバインドされた銀行カードの名前と一致する必要があります",
    5: "繰り返し設定しないでください！",
  },
  SetPayPassword: {
    1: "資金パスワードの設定",
    2: "資金パスワードを入力してください",
    3: "資金パスワードを再入力してください",
  },
  Setsex: {
    1: "性別の変更",
    2: "男",
    3: "女",
  },
  Setting: {
    1: "設定",
    2: "基本情報設定",
    3: "ログインパスワード",
    4: "資金パスワード",
    5: "ログアウト",
    6: "未設定",
    7: "出金パスワードが設定されています。変更が必要な場合はカスタマーサービスに連絡してください",
    8: "設定済み",
  },
  Withdraw: {
    1: "出金センター",
    2: "出金記録",
    3: "出金金額 (元)",
    4: "すべて",
    5: "1回の限度額：最低",
    6: "元",
    7: "最高",
    8: "出金回数：1日最大出金",
    9: "回",
    10: "到着時間：通常は約5分で到着し、最速で2分以内に到着します",
    11: "限度額の説明",
    12: "残高：",
    13: "今すぐ出金",
    14: "正しい金額を入力してください",
  },
  WithdrawRecord: {
    1: "出金記録",
    2: "データが空です！",
    3: "金額：",
    4: "説明：",
    5: "提出時間：",
    6: "審査時間：",
  },
  lottery: {
    1: "タスク記録",
    2: "プレイルール",
    3: "プレイヒント",
    4: "選択可能な合計値の形態から番号を選んでベットします",
    5: "当選説明",
    6: "3つの当選番号の合計値が11〜18は大、合計値が3〜10は小です。",
    7: "ベット例",
    8: "ベットプラン：小 当選番号：123、つまり小に当選",
    9: "タスクリスト",
    10: "利用可能残高",
    11: "元",
    12: "提出",
    13: "現在の選択番号",
    14: "1ベットあたりの金額",
    15: "金額を入力してください",
    16: "合計",
    17: "ベット",
    18: "合計",
    19: "タスクリスト",
    20: "1ベット",
    21: "注文をクリア",
    22: "提出を確認",
    23: "期番号",
    24: "当選番号",
    25: "かわいい",
    26: "純粋な",
    27: "フェティッシュ",
    28: "セクシー",
    29: "未選択",
    30: "金額エラー！",
    31: "番号を選んでください！",
    32: "金額を入力してください！",
    33: "残高不足です。カスタマーサービスに連絡して入金してください！",
    34: "未選択",
    35: "このタスクを受け取るには管理者に連絡してください！",
    36: "抽選成功、期番号：",
  },
  homePage: {
    1: "おすすめタスク",
    2: "もっと見る",
    3: "人気ランキング",
    4: "もっと見る",
    5: "人気のおすすめ",
    6: "もっと見る",
    7: "再生",
    8: "もっと見る",
    9: "取得中......",
  },
  profile: {
    1: "予約",
    2: "アドバイザーまたは受付に連絡してください",
    3: "予約ホール",
    4: "すべて",
    5: "選妃",
    6: "北京",
  },
  choose: {
    1: "選妃",
    2: "都市大全",
    3: "このプラットフォームは、最もリアルなアウトソーシング+ビジネスエスコート+同都市の情熱を提供します。各ユーザーの個人情報を保護するため、顧客は受付担当者またはプラットフォームのベテランメンバーの実名推薦を通じてのみ参加できます。",
    4: "プロセスと価格",
    5: "どのようなリソースがありますか",
    6: "ネットアイドル、モデル、キャビンアテンダント、若手モデル、大学生、あなたが想像できるものはすべて、このプラットフォームで実現できます",
    7: "サービス範囲は？",
    8: "同都市での無料の出会い、任意の場所への全国出張、国内の一線・二線都市にはすべてあり、三線都市でも受付担当者に連絡して予約を手配できます。",
    9: "北京",
    10: "上海",
    11: "広州",
    12: "深圳",
    13: "南京",
    14: "長沙",
    15: "重慶",
    16: "西安",
  },
  Footer: {
    1: "ホーム",
    2: "予約",
    3: "ビデオ",
    4: "マイページ",
  },
  Axios: {
    1: "リクエスト失敗",
  },
  video: {
    1: "ビデオシアター",
    2: "これ以上ありません",
    3: "再生",
    4: "回再生",
    5: "人気のおすすめ",
    6: "アカウントオフライン",
    7: "チャージ後にビデオを視聴してください！",
  },
  获取成功: "取得成功",
  "数据获取成功！": "データ取得成功！",
  "余额不足！": "残高不足！",
  "扣款失败！": "引き落とし失敗！",
  "下注异常！": "ベット異常！",
  "投注成功！": "ベット成功！",
  "参数异常！": "パラメータ異常！",
  数据获取失败: "データ取得失敗",
  鉴权错误: "認証エラー",
  "更换头像成功！": "アバター変更成功！",
  "更新头像失败！": "アバター更新失敗！",
  "请输入真实姓名后再填写银行卡！":
    "実名を入力してから銀行カードを記入してください！",
  "请输入提现密码后再填写银行卡！":
    "出金パスワードを入力してから銀行カードを記入してください！",
  "已设置过银行卡！": "銀行カードは既に設定されています！",
  "设置银行卡成功！": "銀行カード設定成功！",
  "设置银行卡失败！": "銀行カード設定失敗！",
  "设置提现密码成功！": "出金パスワード設定成功！",
  "设置提现密码失败！": "出金パスワード設定失敗！",
  旧密码错误: "旧パスワードエラー",
  "修改密码成功！": "パスワード変更成功！",
  "修改密码失败！": "パスワード変更失敗！",
  "请输入真实姓名！": "実名を入力してください！",
  "设置姓名成功！": "名前設定成功！",
  "设置姓名失败！": "名前設定失敗！",
  "设置性别成功！": "性別設定成功！",
  "设置性别失败！": "性別設定失敗！",
  打码量不足: "コーディング量不足",
  "提现金额不能少于：": "出金金額は以下より少なくてはなりません：",
  "提现金额不能大于：": "出金金額は以下より多くてはなりません：",
  当日提现次数已用完: "当日の出金回数が上限に達しました",
  "提现成功！": "出金成功！",
  "提现失败！": "出金失敗！",
  "获取信息成功！": "情報取得成功！",
  "用户不存在！": "ユーザーが存在しません！",
  "密码错误！": "パスワードが間違っています！",
  "账号被禁用！": "アカウントが無効になっています！",
  "登录成功！": "ログイン成功！",
  "用户名不能存在中文！": "ユーザー名に中国語を含めることはできません！",
  "用户名位数错误！": "ユーザー名の桁数が間違っています！",
  "密码位数错误！": "パスワードの桁数が間違っています！",
  "邀请码不能为空！": "招待コードは空にできません！",
  "邀请码不存在！": "招待コードが存在しません！",
  "注册成功！": "登録成功！",
  "注册失败！": "登録失敗！",
  用户已存在: "ユーザーが既に存在します",
  id为空: "IDが空です",
};
