// 为 Vue 的根实例设置语言环境信息
import Vue from "vue";
import VueI18n from "vue-i18n";
import zh from "./zh";
import en from "./en";
import ko from "./ko";
import ja from "./ja";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: "ko",
  messages: {
    zh,
    en,
    ko,
    ja,
  },
});
