export default {
  login: "Login",
  register: "Register",
  deposit: "Deposit",
  withdraw: "Withdraw",
  confirm: "Confirm",
  refreshSuccess: "Refresh successful",
  save: "Save",
  submit: "Submit",
  loginPage: {
    1: "Please enter username",
    2: "Please enter password",
    3: "Forgot password?",
    4: "Don't have an account? Register now",
  },
  registerPage: {
    1: "Please enter username (6 - 12 letters or numbers)",
    2: "Please enter login password (6-12 letters or numbers)",
    3: "Please enter invitation code",
    4: `I have read and agree to the "Account Opening Agreement" terms`,
    5: "Please enter username!",
    6: "Please enter password!",
    7: "Please check the account opening agreement below!",
  },
  minePage: {
    1: "My Wallet",
    2: "Details",
    3: "Balance",
    4: "Personal Report",
    5: "Account Details",
    6: "Task Records",
    7: "Personal Center",
    8: "Information Bulletin",
    9: "Online Customer Service",
    10: "",
    11: "Please complete the task sheet before entering",
    12: "Please contact customer service for recharge",
    13: "Please set up the receiving card!",
    14: "Function disabled!",
    15: "Account Offline",
    16: "Login/Register",
    17: "Log in to enjoy more services!",
  },
  BindCard: {
    1: "Fill in the Receiving Card",
    2: "Please enter your receiving card information",
    3: "Bank Card Number",
    4: "Please enter the real bank card number",
    5: "Bank Name",
    6: "Please select a bank",
    7: "Dear user, to ensure the safety of your funds, please bind your real name and set a withdrawal password. If the name does not match the account name, withdrawal will not be possible.",
    8: "Confirm Card Binding",
  },
  GameRecord: {
    1: "Task Records",
    2: "Data is empty!",
    3: "Big",
    4: "Small",
    5: "Even",
    6: "Odd",
    7: "Bet Amount:",
    8: "Order Time:",
    9: "Settlement Time:",
    10: "",
  },
  Infomation: {
    1: "Basic Information",
    2: "Avatar",
    3: "Select Avatar",
    4: "Real Name",
    5: "Not Set",
    6: "Gender",
    7: "Male",
    8: "Female",
    9: "Unknown",
    10: "Binding Information",
    11: "Bound",
    12: "None",
  },
  Notice: {
    1: "System Notice",
  },
  Personalreport: {
    1: "Personal Report",
    2: "Profit Amount (CNY)",
    3: "Profit Calculation Formula: Winning Amount - Task Amount",
    4: "Task Amount",
    5: "Recharge Amount",
    6: "Withdrawal Amount",
    7: "Winning Amount",
  },
  ServiceOnline: {
    1: "Online Customer Service",
    2: "Mihuan Entertainment",
    3: "Contact",
    4: "24/7 Service at Your Disposal",
  },
  Setbank: {
    1: "Payment Card Information",
    2: "Add Payment Card",
    3: "Note: Please bind a major commercial bank. If you need to modify it, contact online customer service.",
    4: "Please set your name before binding the bank card!",
    5: "Please set the withdrawal password before binding the bank card!",
  },
  SetLoginPassword: {
    1: "Change Login Password",
    2: "Old Password",
    3: "New Password",
    4: "Enter your old password",
    5: "Enter your new password",
    6: "Re-enter your new password",
    7: "Please complete all fields!",
    8: "Passwords do not match.",
  },
  Setname: {
    1: "Change Real Name",
    2: "Name",
    3: "Enter your real name",
    4: "For account security, your real name must match the name on your bound bank card.",
    5: "Do not set it repeatedly!",
  },
  SetPayPassword: {
    1: "Set Payment Password",
    2: "Enter your payment password",
    3: "Re-enter your payment password",
  },
  Setsex: {
    1: "Change Gender",
    2: "Male",
    3: "Female",
  },
  Setting: {
    1: "Settings",
    2: "Basic Information Settings",
    3: "Login Password",
    4: "Payment Password",
    5: "Logout",
    6: "Not Set",
    7: "The withdrawal password has been set. Contact customer service if you want to modify it.",
    8: "Setting Completed",
  },
  Withdraw: {
    1: "Withdrawal Center",
    2: "Withdrawal Record",
    3: "Withdrawal Amount (CNY)",
    4: "Total",
    5: "Single Withdrawal Limit: Minimum",
    6: "CNY",
    7: "Maximum",
    8: "Max daily withdrawal times",
    9: "Times",
    10: "Processing time: Usually processed within 5 minutes, the earliest within 2 minutes",
    11: "Limit Explanation",
    12: "Balance:",
    13: "Withdraw Now",
    14: "Please enter the correct amount",
  },
  WithdrawRecord: {
    1: "Withdrawal Record",
    2: "No data available!",
    3: "Amount:",
    4: "Explanation:",
    5: "Submission Time:",
    6: "Review Time:",
  },
  lottery: {
    1: "Task Record",
    2: "Game Rules",
    3: "Game Tips",
    4: "Select numbers for betting among the sum shapes",
    5: "Winning Description",
    6: "If the sum of the three winning numbers is between 11~18, it's a big value; 3~10 is a small value.",
    7: "Bet Example",
    8: "Bet plan: Small value, Winning numbers: 123, Small value won",
    9: "Task Unit",
    10: "Available Balance",
    11: "CNY",
    12: "Submit",
    13: "Current Number Selection",
    14: "Amount per Bet",
    15: "Please enter the amount",
    16: "Total",
    17: "Bet",
    18: "Total Sum",
    19: "Task Unit",
    20: "1 Bet",
    21: "Cancel Order",
    22: "Confirm Submission",
    23: "Round Number",
    24: "Winning Number",
    25: "Big Value",
    26: "Small Value",
    27: "Even",
    28: "Odd",
    29: "Not Selected",
    30: "Amount Error!",
    31: "Please select a number!",
    32: "Please enter the amount!",
    33: "Insufficient balance, please contact customer service for recharge!",
    34: "Not Selected",
    35: "Contact the administrator to receive the task!",
    36: "Lottery success, Round number:",
  },
  homePage: {
    1: "Recommended Tasks",
    2: "More",
    3: "Popular Ranking",
    4: "More",
    5: "Popular Recommendations",
    6: "More",
    7: "Replay",
    8: "More",
    9: "Loading......",
  },
  profile: {
    1: "Reservation",
    2: "Contact a Consultant or Receptionist",
    3: "Reservation Lounge",
    4: "All",
    5: "Female Selection",
    6: "Beijing",
  },
  choose: {
    1: "Female Selection",
    2: "All Cities",
    3: "This platform offers the most genuine external and business escort services. For the privacy of each user, customers can only join through a receptionist or platform member.",
    4: "Procedures and Prices",
    5: "What Resources Are Available",
    6: "We offer influencers, models, flight attendants, university students, and more.",
    7: "Service Scope?",
    8: "You can meet for free in the same city, and requests can be made nationwide.",
    9: "Beijing",
    10: "Shanghai",
    11: "Guangzhou",
    12: "Shenzhen",
    13: "Nanjing",
    14: "Changsha",
    15: "Chongqing",
    16: "Xi'an",
  },
  Footer: {
    1: "Home",
    2: "Reservation",
    3: "Video",
    4: "My Profile",
  },
  Axios: {
    1: "Request failed",
  },
  video: {
    1: "Video Theater",
    2: "No more",
    3: "Play",
    4: "Plays",
    5: "Popular Recommendations",
    6: "Account Offline",
    7: "Please recharge to watch the video!",
  },
  获取成功: "Fetch successful",
  "数据获取成功！": "Data fetch successful!",
  "余额不足！": "Insufficient balance!",
  "扣款失败！": "Deduction failed!",
  "下注异常！": "Betting error!",
  "投注成功！": "Bet successful!",
  "参数异常！": "Parameter error!",
  数据获取失败: "Data fetch failed",
  鉴权错误: "Authentication error",
  "更换头像成功！": "Avatar change successful!",
  "更新头像失败！": "Avatar update failed!",
  "请输入真实姓名后再填写银行卡！":
    "Please enter your real name before filling in the bank card!",
  "请输入提现密码后再填写银行卡！":
    "Please enter the withdrawal password before filling in the bank card!",
  "已设置过银行卡！": "Bank card already set!",
  "设置银行卡成功！": "Bank card setup successful!",
  "设置银行卡失败！": "Bank card setup failed!",
  "设置提现密码成功！": "Withdrawal password setup successful!",
  "设置提现密码失败！": "Withdrawal password setup failed!",
  旧密码错误: "Old password incorrect",
  "修改密码成功！": "Password change successful!",
  "修改密码失败！": "Password change failed!",
  "请输入真实姓名！": "Please enter your real name!",
  "设置姓名成功！": "Name setup successful!",
  "设置姓名失败！": "Name setup failed!",
  "设置性别成功！": "Gender setup successful!",
  "设置性别失败！": "Gender setup failed!",
  打码量不足: "Insufficient coding amount",
  "提现金额不能少于：": "Withdrawal amount cannot be less than:",
  "提现金额不能大于：": "Withdrawal amount cannot be more than:",
  当日提现次数已用完: "Daily withdrawal limit reached",
  "提现成功！": "Withdrawal successful!",
  "提现失败！": "Withdrawal failed!",
  "获取信息成功！": "Information fetch successful!",
  "用户不存在！": "User does not exist!",
  "密码错误！": "Incorrect password!",
  "账号被禁用！": "Account disabled!",
  "登录成功！": "Login successful!",
  "用户名不能存在中文！": "Username cannot contain Chinese characters!",
  "用户名位数错误！": "Username length incorrect!",
  "密码位数错误！": "Password length incorrect!",
  "邀请码不能为空！": "Invitation code cannot be empty!",
  "邀请码不存在！": "Invitation code does not exist!",
  "注册成功！": "Registration successful!",
  "注册失败！": "Registration failed!",
  用户已存在: "User already exists",
  id为空: "ID is empty",
};
