export default {
  login: "로그인",
  register: "회원가입",
  deposit: "충전",
  withdraw: "출금",
  confirm: "확인",
  refreshSuccess: "새로 고침 성공",
  save: "저장",
  submit: "제출",
  loginPage: {
    1: "사용자 이름을 입력하세요",
    2: "비밀번호를 입력하세요",
    3: "비밀번호를 잊으셨나요?",
    4: "계정이 없으신가요? 지금 가입하세요",
  },
  registerPage: {
    1: "사용자 이름을 입력하세요 (6 - 12자의 영문자 또는 숫자)",
    2: "로그인 비밀번호를 입력하세요 (6-12자의 영문자 또는 숫자)",
    3: "초대 코드를 입력하세요",
    4: `저는 "계좌 개설 약관"의 모든 조항을 읽고 동의합니다`,
    5: "사용자 이름을 입력하세요!",
    6: "비밀번호를 입력하세요!",
    7: "아래의 계좌 개설 약관에 동의해주세요!",
  },
  minePage: {
    1: "내 지갑",
    2: "상세 정보",
    3: "잔액",
    4: "개인 보고서",
    5: "계정 내역",
    6: "작업 기록",
    7: "개인 센터",
    8: "정보 공지",
    9: "온라인 고객 서비스",
    10: "",
    11: "작업을 완료한 후에 들어가세요",
    12: "충전을 위해 고객 서비스에 문의하세요",
    13: "수취 카드를 설정하세요!",
    14: "기능이 비활성화되었습니다!",
    15: "계정 로그아웃",
    16: "로그인/회원가입",
    17: "로그인하면 더 많은 서비스를 이용할 수 있습니다!",
  },
  BindCard: {
    1: "수취 카드 작성",
    2: "수취 카드 정보를 입력하세요",
    3: "은행 카드 번호",
    4: "실제 은행 카드 번호를 입력하세요",
    5: "은행 이름",
    6: "은행을 선택하세요",
    7: "존경하는 사용자님, 자금 안전을 위해 실명을 등록하고 출금 비밀번호를 설정해 주세요. 이름이 계좌 개설 이름과 일치하지 않으면 출금할 수 없습니다.",
    8: "카드 등록 확인",
  },
  GameRecord: {
    1: "작업 기록",
    2: "데이터가 비어 있습니다!",
    3: "청순한",
    4: "섹시한",
    5: "패티쉬",
    6: "귀여운",
    7: "베팅 금액:",
    8: "주문 시간:",
    9: "정산 시간:",
    10: "",
  },
  Infomation: {
    1: "기본 정보",
    2: "프로필 사진",
    3: "프로필 사진 선택",
    4: "실명",
    5: "설정되지 않음",
    6: "성별",
    7: "남성",
    8: "여성",
    9: "알 수 없음",
    10: "연결 정보",
    11: "연결됨",
    12: "없음",
  },
  Notice: {
    1: "시스템 공지",
  },
  Personalreport: {
    1: "개인 보고서",
    2: "수익 금액(원)",
    3: "수익 계산 공식 : 당첨 금액 - 작업 금액",
    4: "작업 금액",
    5: "충전 금액",
    6: "출금 금액",
    7: "당첨 금액",
  },
  ServiceOnline: {
    1: "온라인 고객센터",
    2: "밋구원 엔터테인먼트",
    3: "연락하기",
    4: "연중무휴 24시간 성심껏 서비스 제공",
  },
  Setbank: {
    1: "수취 계좌 정보",
    2: "수취 계좌 추가",
    3: "알림: 대형 상업은행을 연결하세요. 변경이 필요하면 온라인 고객센터에 연락하세요.",
    4: "이름을 설정한 후에 은행 카드를 연결하세요!",
    5: "출금 비밀번호를 설정한 후에 은행 카드를 연결하세요!",
  },
  SetLoginPassword: {
    1: "로그인 비밀번호 변경",
    2: "이전 비밀번호",
    3: "새 비밀번호",
    4: "이전 비밀번호를 입력하세요",
    5: "새 비밀번호를 입력하세요",
    6: "새 비밀번호를 다시 입력하세요",
    7: "모든 정보를 입력하세요!",
    8: "비밀번호가 일치하지 않습니다.",
  },
  Setname: {
    1: "실명 변경",
    2: "이름",
    3: "실명을 입력하세요",
    4: "계정 보안을 위해 실명은 연결된 은행 카드의 이름과 일치해야 합니다.",
    5: "중복 설정하지 마세요!",
  },
  SetPayPassword: {
    1: "결제 비밀번호 설정",
    2: "결제 비밀번호를 입력하세요",
    3: "결제 비밀번호를 다시 입력하세요",
  },
  Setsex: {
    1: "성별 수정",
    2: "남",
    3: "여",
  },
  Setting: {
    1: "설정",
    2: "기본 정보 설정",
    3: "로그인 비밀번호",
    4: "결제 비밀번호",
    5: "로그아웃",
    6: "설정되지 않음",
    7: "출금 비밀번호가 설정되었습니다. 변경하려면 고객센터에 연락하세요.",
    8: "설정 완료",
  },
  Withdraw: {
    1: "출금 센터",
    2: "출금 기록",
    3: "출금 금액(원)",
    4: "전체",
    5: "단일 출금 한도: 최소",
    6: "원",
    7: "최대",
    8: "하루 최대 출금 횟수",
    9: "번",
    10: "처리 시간: 일반적으로 5분 내 처리, 빠르면 2분 내 도착",
    11: "한도 설명",
    12: "잔액:",
    13: "지금 출금",
    14: "정확한 금액을 입력하세요",
  },
  WithdrawRecord: {
    1: "출금 기록",
    2: "데이터 없음!",
    3: "금액:",
    4: "설명:",
    5: "제출 시간:",
    6: "검토 시간:",
  },
  lottery: {
    1: "작업 기록",
    2: "게임 규칙",
    3: "게임 팁",
    4: "선택한 합계 모양 중에서 번호를 선택하여 베팅",
    5: "당첨 설명",
    6: "세 개의 당첨 번호의 총 합계가 11~18은 큰, 3~10은 작은 값입니다.",
    7: "베팅 예시",
    8: "베팅 계획: 작은 값, 당첨 번호: 123, 작은 값 당첨",
    9: "작업 단위",
    10: "사용 가능한 잔액",
    11: "원",
    12: "제출",
    13: "현재 번호 선택",
    14: "한 번의 베팅 금액",
    15: "금액을 입력하세요",
    16: "총",
    17: "베팅",
    18: "총합계",
    19: "작업 단위",
    20: "1회 베팅",
    21: "주문 삭제",
    22: "제출 확인",
    23: "회차 번호",
    24: "당첨 번호",
    25: "청순한",
    26: "섹시한",
    27: "패티쉬",
    28: "귀여운",
    29: "선택되지 않음",
    30: "금액 오류!",
    31: "번호를 선택하세요!",
    32: "금액을 입력하세요!",
    33: "잔액 부족, 고객센터에 문의하여 충전하세요!",
    34: "선택되지 않음",
    35: "관리자에게 연락하여 작업을 받으세요!",
    36: "추첨 성공, 회차 번호:",
  },
  homePage: {
    1: "추천 작업",
    2: "더보기",
    3: "인기 순위",
    4: "더보기",
    5: "인기 추천",
    6: "더보기",
    7: "재생",
    8: "더보기",
    9: "로드 중......",
  },
  profile: {
    1: "예약",
    2: "컨설턴트 또는 접수 담당자에게 연락하세요",
    3: "예약 대기실",
    4: "전체",
    5: "여성 선택",
    6: "베이징",
  },
  choose: {
    1: "여성 선택",
    2: "도시 전체",
    3: "이 플랫폼은 가장 실제적인 외부 및 비즈니스 에스코트 서비스를 제공합니다. 각 사용자 개인정보 보호를 위해 고객은 접수 담당자나 플랫폼의 회원을 통해서만 가입할 수 있습니다.",
    4: "절차 및 가격",
    5: "어떤 리소스가 있나요",
    6: "인플루언서, 모델, 승무원, 대학생 등 다양한 서비스를 제공합니다.",
    7: "서비스 범위?",
    8: "같은 도시에 무료로 만날 수 있으며, 전국 어디든 요청할 수 있습니다.",
    9: "베이징",
    10: "상하이",
    11: "광저우",
    12: "선전",
    13: "난징",
    14: "창사",
    15: "충칭",
    16: "시안",
  },
  Footer: {
    1: "홈",
    2: "예약",
    3: "비디오",
    4: "내 프로필",
  },
  Axios: {
    1: "요청 실패",
  },
  video: {
    1: "비디오 극장",
    2: "더 이상 없음",
    3: "재생",
    4: "회 재생",
    5: "인기 추천",
    6: "계정 로그아웃",
    7: "충전 후에 비디오를 시청하세요!",
  },
  获取成功: "성공적으로 가져오기",
  "数据获取成功！": "데이터 가져오기 성공!",
  "余额不足！": "잔액 부족!",
  "扣款失败！": "공제 실패!",
  "下注异常！": "베팅 이상!",
  "投注成功！": "베팅 성공!",
  "参数异常！": "매개변수 이상!",
  数据获取失败: "데이터 가져오기 실패",
  鉴权错误: "인증 오류",
  "更换头像成功！": "아바타 변경 성공!",
  "更新头像失败！": "아바타 업데이트 실패!",
  "请输入真实姓名后再填写银行卡！":
    "실제 이름을 입력한 후 은행 카드를 작성하세요!",
  "请输入提现密码后再填写银行卡！":
    "출금 비밀번호를 입력한 후 은행 카드를 작성하세요!",
  "已设置过银行卡！": "은행 카드가 이미 설정되었습니다!",
  "设置银行卡成功！": "은행 카드 설정 성공!",
  "设置银行卡失败！": "은행 카드 설정 실패!",
  "设置提现密码成功！": "출금 비밀번호 설정 성공!",
  "设置提现密码失败！": "출금 비밀번호 설정 실패!",
  旧密码错误: "이전 비밀번호 오류",
  "修改密码成功！": "비밀번호 변경 성공!",
  "修改密码失败！": "비밀번호 변경 실패!",
  "请输入真实姓名！": "실제 이름을 입력하세요!",
  "设置姓名成功！": "이름 설정 성공!",
  "设置姓名失败！": "이름 설정 실패!",
  "设置性别成功！": "성별 설정 성공!",
  "设置性别失败！": "성별 설정 실패!",
  打码量不足: "코딩량 부족",
  "提现金额不能少于：": "출금 금액은 다음보다 적을 수 없습니다:",
  "提现金额不能大于：": "출금 금액은 다음보다 클 수 없습니다:",
  当日提现次数已用完: "당일 출금 횟수 초과",
  "提现成功！": "출금 성공!",
  "提现失败！": "출금 실패!",
  "获取信息成功！": "정보 가져오기 성공!",
  "用户不存在！": "사용자가 존재하지 않습니다!",
  "密码错误！": "비밀번호 오류!",
  "账号被禁用！": "계정이 비활성화되었습니다!",
  "登录成功！": "로그인 성공!",
  "用户名不能存在中文！": "사용자 이름에 중국어가 포함될 수 없습니다!",
  "用户名位数错误！": "사용자 이름 자릿수 오류!",
  "密码位数错误！": "비밀번호 자릿수 오류!",
  "邀请码不能为空！": "초대 코드는 비워둘 수 없습니다!",
  "邀请码不存在！": "초대 코드가 존재하지 않습니다!",
  "注册成功！": "등록 성공!",
  "注册失败！": "등록 실패!",
  用户已存在: "사용자가 이미 존재합니다",
  id为空: "ID가 비어 있습니다",
};
