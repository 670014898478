export default {
  login: "登录",
  register: "注册",
  deposit: "充值",
  withdraw: "提现",
  confirm: "确认",
  refreshSuccess: "刷新成功",
  save: "保存",
  submit: "提交",
  loginPage: {
    1: "请输入用户名",
    2: "请输入密码",
    3: "忘记密码？",
    4: "没有账户？马上注册",
  },
  registerPage: {
    1: "请输入用户名(6 - 12个英文字母或数字)",
    2: "请输入登录密码(6-12个英文字母或数字)",
    3: "请输入邀请码",
    4: `我已经知晓并同意"开户协议"各项条约`,
    5: "请输入用户名！",
    6: "请输入密码！",
    7: "请勾选下方开户协议！",
  },
  minePage: {
    1: "我的钱包",
    2: "详情",
    3: "余额",
    4: "个人报表",
    5: "账户明细",
    6: "任务记录",
    7: "个人中心",
    8: "信息公告",
    9: "在线客服",
    10: "",
    11: "请完成任务单后进入",
    12: "请联系客服充值",
    13: "请设置收款卡!",
    14: "功能已禁用!",
    15: "账号下线",
    16: "登录/注册",
    17: "登录可享受更多服务！",
  },
  BindCard: {
    1: "填写收款卡",
    2: "请输入您的收款卡信息",
    3: "银行卡号",
    4: "请输入真实银行卡号",
    5: "银行名称",
    6: "请选择银行",
    7: "尊敬的用户,为了保障您的资金安全,请您绑定您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款",
    8: "确认绑卡",
  },
  GameRecord: {
    1: "任务记录",
    2: "数据为空！",
    3: "大",
    4: "小",
    5: "双",
    6: "单",
    7: "下注金额：",
    8: "下单时间：",
    9: "结算时间：",
    10: "",
  },
  Infomation: {
    1: "基本信息",
    2: "头像",
    3: "选择头像",
    4: "真实姓名",
    5: "未设置",
    6: "性别",
    7: "男",
    8: "女",
    9: "未知",
    10: "绑定信息",
    11: "已绑定",
    12: "无",
  },
  Notice: {
    1: "系统公告",
  },
  Personalreport: {
    1: "个人报表",
    2: "盈利金额(元)",
    3: "盈利计算公式 : 中奖金额 - 任务金额",
    4: "任务金额",
    5: "充值金额",
    6: "提现金额",
    7: "中奖金额",
  },
  ServiceOnline: {
    1: "在线客服",
    2: "蜜獾娱乐",
    3: "联系",
    4: "全天7 * 24小时竭诚为您服务",
  },
  Setbank: {
    1: "收款卡信息",
    2: "添加收款卡",
    3: "提示:请绑定大型商业银行,如需修改,请您联系在线客服",
    4: "请设置姓名后再绑定银行卡！",
    5: "请设置提现密码后再绑定银行卡！",
  },
  SetLoginPassword: {
    1: "修改登录密码",
    2: "旧密码",
    3: "新密码",
    4: "请输入您的旧密码",
    5: "请输入您的新密码",
    6: "请再次输入您的新密码",
    7: "请填写完整！",
    8: "两次密码输入不一致",
  },
  Setname: {
    1: "修改真实姓名",
    2: "姓名",
    3: "请输入真实姓名",
    4: "为了您账户安全,真实姓名需要与绑定银行卡姓名一致",
    5: "请勿重复设置！",
  },
  SetPayPassword: {
    1: "设置资金密码",
    2: "请输入资金密码",
    3: "请再次输入资金密码",
  },
  Setsex: {
    1: "性别修改",
    2: "男",
    3: "女",
  },
  Setting: {
    1: "设置",
    2: "基本信息设置",
    3: "登录密码",
    4: "资金密码",
    5: "退出登录",
    6: "未设置",
    7: "提现密码已设置，需要修改请联系客服",
    8: "已设置",
  },
  Withdraw: {
    1: "提现中心",
    2: "提现记录",
    3: "提现金额 (元)",
    4: "全部",
    5: "单笔限额：最低",
    6: "元",
    7: "最高",
    8: "提现次数：一天最高提现",
    9: "次",
    10: "到账时间：一般到账时间在5分钟左右，最快2分钟内到账",
    11: "限额说明",
    12: "余额：",
    13: "马上提现",
    14: "请填写正确的金额",
  },
  WithdrawRecord: {
    1: "提现记录",
    2: "数据为空！",
    3: "金额：",
    4: "说明：",
    5: "提交时间：",
    6: "审核时间：",
  },
  lottery: {
    1: "任务记录",
    2: "玩法规则",
    3: "玩法提示",
    4: "从可选和值形态里面选择号码进行下注",
    5: "中奖说明",
    6: "三个开奖号码总和值11~18 为大;总和值3~ 10为小;",
    7: "投注范例",
    8: "投注方案：小 开奖号码：123,即中小",
    9: "任务单",
    10: "可用余额",
    11: "元",
    12: "提交",
    13: "当前选号",
    14: "每注金额",
    15: "请输入金额",
    16: "总共",
    17: "注",
    18: "合计",
    19: "任务单",
    20: "1注",
    21: "清空订单",
    22: "确认提交",
    23: "期号",
    24: "开奖号码",
    25: "大",
    26: "小",
    27: "双",
    28: "单",
    29: "未选择",
    30: "金额错误！",
    31: "请选号！",
    32: "请填写金额！",
    33: "余额不足，请联系客服充值！",
    34: "未选中",
    35: "请联系管理员领取该任务!",
    36: "开奖成功，期号：",
  },
  homePage: {
    1: "推荐任务",
    2: "查看更多",
    3: "人气排行",
    4: "查看更多",
    5: "热门推荐",
    6: "查看更多",
    7: "播放",
    8: "查看更多",
    9: "获取中......",
  },
  profile: {
    1: "预约",
    2: "请联系顾问或接待员",
    3: "预约大厅",
    4: "全部",
    5: "选妃",
    6: "北京",
  },
  choose: {
    1: "选妃",
    2: "城市大全",
    3: "本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。",
    4: "流程价格",
    5: "有哪些资源",
    6: "网红、模特、空姐、嫩模、大学生，只有您想不到，没有本平台办不到",
    7: "服务范围?",
    8: "同城免费约炮，任意地点全国空降，国内一二线城市当地均有，三线城市也可通过联系接待员预约安排。",
    9: "北京",
    10: "上海",
    11: "广州",
    12: "深圳",
    13: "南京",
    14: "长沙",
    15: "重庆",
    16: "西安",
  },
  Footer: {
    1: "首页",
    2: "预约",
    3: "视频",
    4: "我的",
  },
  Axios: {
    1: "请求失败",
  },
  video: {
    1: "视频影院",
    2: "没有更多了",
    3: "播放",
    4: "次播放",
    5: "热门推荐",
    6: "账号下线",
    7: "请充值后观看视频！",
  },
  获取成功: "获取成功",
  "数据获取成功！": "数据获取成功！",
  "余额不足！": "余额不足！",
  "扣款失败！": "扣款失败！",
  "下注异常！": "下注异常！",
  "投注成功！": "投注成功！",
  "参数异常！": "参数异常！",
  数据获取失败: "数据获取失败",
  鉴权错误: "鉴权错误",
  "更换头像成功！": "更换头像成功！",
  "更新头像失败！": "更新头像失败！",
  "请输入真实姓名后再填写银行卡！": "请输入真实姓名后再填写银行卡！",
  "请输入提现密码后再填写银行卡！": "请输入提现密码后再填写银行卡！",
  "已设置过银行卡！": "已设置过银行卡！",
  "设置银行卡成功！": "设置银行卡成功！",
  "设置银行卡失败！": "设置银行卡失败！",
  "设置提现密码成功！": "设置提现密码成功！",
  "设置提现密码失败！": "设置提现密码失败！",
  旧密码错误: "旧密码错误",
  "修改密码成功！": "修改密码成功！",
  "修改密码失败！": "修改密码失败！",
  "请输入真实姓名！": "请输入真实姓名！",
  "设置姓名成功！": "设置姓名成功！",
  "设置姓名失败！": "设置姓名失败！",
  "设置性别成功！": "设置性别成功！",
  "设置性别失败！": "设置性别失败！",
  打码量不足: "打码量不足",
  "提现金额不能少于：": "提现金额不能少于：",
  "提现金额不能大于：": "提现金额不能大于：",
  当日提现次数已用完: "当日提现次数已用完",
  "提现成功！": "提现成功！",
  "提现失败！": "提现失败！",
  "获取信息成功！": "获取信息成功！",
  "用户不存在！": "用户不存在！",
  "密码错误！": "密码错误！",
  "账号被禁用！": "账号被禁用！",
  "登录成功！": "登录成功！",
  "用户名不能存在中文！": "用户名不能存在中文！",
  "用户名位数错误！": "用户名位数错误！",
  "密码位数错误！": "密码位数错误！",
  "邀请码不能为空！": "邀请码不能为空！",
  "邀请码不存在！": "邀请码不存在！",
  "注册成功！": "注册成功！",
  "注册失败！": "注册失败！",
  用户已存在: "用户已存在",
  id为空: "id为空",
};
