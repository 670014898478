var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container page"},[_c('van-nav-bar',{staticClass:"nav-bar",attrs:{"title":_vm.$t('GameRecord.1')},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('van-icon',{attrs:{"name":"arrow-left","color":"#fff"},on:{"click":function($event){return _vm.back()}}})]},proxy:true}])}),_c('div',{staticClass:"main"},[_c('van-pull-refresh',{attrs:{"pulling-text":' ',"loosing-text":' ',"loading-text":' ',"success-text":' '},on:{"refresh":_vm.onRefresh},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}},[(_vm.list.length === 0)?_c('van-empty',{attrs:{"description":_vm.$t('GameRecord.2')}}):_vm._l((_vm.list),function(v,key){return _c('div',{key:key,staticClass:"item_list"},[_c('div',{staticClass:"lottery_info"},[_c('van-image',{staticClass:"cover",attrs:{"src":v?.ico},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('van-loading',{attrs:{"type":"spinner"}})]},proxy:true}],null,true)}),_c('span',{staticClass:"period-number"},[_vm._v(_vm._s(v.expect))]),_c('span',{staticClass:"period-number"},[_vm._v(_vm._s(_vm.handleTitle(v)))])],1),_c('div',{staticClass:"recent"},[_c('div',{staticClass:"kuaisan-ball left"},[_c('van-image',{staticClass:"res-img",attrs:{"src":v.status === 0
              ? 'img/lottery/open_num.gif'
              : 'img/lottery/shaizi/' + v.opencode[0] + '.png'},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('van-loading',{attrs:{"type":"spinner"}})]},proxy:true}],null,true)}),_c('van-image',{staticClass:"res-img",attrs:{"src":v.status === 0
              ? 'img/lottery/open_num.gif'
              : 'img/lottery/shaizi/' + v.opencode[1] + '.png'},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('van-loading',{attrs:{"type":"spinner"}})]},proxy:true}],null,true)}),_c('van-image',{staticClass:"res-img",attrs:{"src":v.status === 0
              ? 'img/lottery/open_num.gif'
              : 'img/lottery/shaizi/' + v.opencode[2] + '.png'},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('van-loading',{attrs:{"type":"spinner"}})]},proxy:true}],null,true)}),_c('span',{staticClass:"res-des middle"},[_vm._v(_vm._s(v.status === 0 ? 0 : v.opencode[0] + v.opencode[1] + v.opencode[2]))]),_c('span',{staticClass:"res-des middle"},[_vm._v(" "+_vm._s(v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] + v.opencode[2] >= 11 && v.opencode[0] + v.opencode[1] + v.opencode[2] <= 18) ? _vm.$t("GameRecord.3") : _vm.$t("GameRecord.4"))+" ")]),_c('span',{staticClass:"res-des middle"},[_vm._v(_vm._s(v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] + v.opencode[2]) % 2 === 0 ? _vm.$t("GameRecord.5") : _vm.$t("GameRecord.6")))])],1)]),_c('div',{staticClass:"topInfo"},[(v.status === 1)?_c('span',{staticStyle:{"color":"#07c160"}},[_vm._v(_vm._s(v.status_text))]):_c('span',[_vm._v(_vm._s(v.status_text))]),_c('span',[_vm._v(_vm._s(_vm.$t("GameRecord.7"))+_vm._s(v.money))])]),_c('div',{staticClass:"time"},[_c('span',[_vm._v(_vm._s(_vm.$t("GameRecord.8"))+_vm._s(v.create_time))])]),_c('div',{staticClass:"time"},[_c('span',[_vm._v(_vm._s(_vm.$t("GameRecord.9"))+_vm._s(v.update_time))])])])})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }