<template>
    <div class="langWrap">
        <img class="icon" src="/img/login/translation.png" alt="">
        <ul class="list">
            <!-- <li @click="setLang('zh')">中文</li> -->
            <li @click="setLang('en')">English</li>
            <li @click="setLang('ko')">한국어</li>
            <li @click="setLang('ja')">日本語</li>
        </ul>
    </div>
</template>

<script>
export default {
    methods: {
        setLang(lang) {
            this.$i18n.locale = lang
            localStorage.setItem('lang', lang)
        },
    }
}
</script>

<style lang="scss" scoped>
.langWrap {
    position: relative;
    display: flex;
    align-items: center;

    .icon {
        width: 50px;
        height: 50px;
        background-color: #fff;
        border-radius: 8px;
    }

    &:hover {
        .list {
            display: block;
        }
    }

    .list {
        display: none;
        position: absolute;
        left: 0%;
        top: 100%;
        transform: translateX(-50%);
        background-color: #fff;
        border-radius: 10px;
        padding: 10px;
        font-size: 30px;
        z-index: 10;

        // &::before {
        //   content: '';
        //   width: 0;
        //   height: 0;
        //   border-left: 20px solid transparent;
        //   border-right: 20px solid transparent;
        //   border-bottom: 20px solid #fff;
        //   /* 颜色可以根据需要更改 */
        //   position: absolute;
        //   top: 0;
        //   left: 50%;
        //   transform: translate(-10%, -100%);
        //   /* border: ; */

        // }

        li {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
</style>